.auth-page {
  background-image: url('../images/bg-yellow.png');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat !important;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
}

.auth-nav {
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.3),
    black
  );
  text-align: end;
  padding: 0.4rem 2rem;
  margin-bottom: 50px !important;
  a {
    color: #e5d92fc2;
    font-size: 0.9rem;
    &:hover {
      color: #e5d92f;
    }
  }
}

.auth-page.for-signup {
  padding-bottom: 50px;
}

.auth-page-content {
  background-color: #000;
  width: 80%;
  margin: auto;
  border-radius: 15px;
  resize: vertical;
  overflow: auto;
}
.image-logo-round {
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: -41px;
  left: 47%;
}

.auth-page-content h3 {
  margin-top: 24px;
  font-size: 24px;
  position: relative;
}
.auth-page-content h5 {
  padding-top: 0px !important;
  font-size: 18px;
}

// SIGN-UP NAVIGATION
.signup-form-nav {
  width: 80%;
}
.signup-form-nav span.col-md-3 {
  padding-left: 0px !important;
  &:last-child {
    padding-right: 0px;
  }
}

.signup-form-nav span.form-nav-indicator {
  display: inline-block;
  font-size: 15px;
  border-bottom: 4px solid $primary;
  width: 100%;
  padding-bottom: 3px;
}

.signup-form-nav span.form-nav-indicator span.number-indicator {
  font-size: 12px;
  font-weight: normal !important;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 0px 5px;
}

.signup-form-nav
  span.form-nav-indicator
  span.number-indicator.number-indicator-border {
  border: none;
}
.check-success {
  vertical-align: top;
}

.signup-form-nav span.disable-indicator {
  opacity: 0.4;
}

// REGISTRATION FORM

.register-content {
  width: 80%;
}

.register-content .col-md-6.div-1,
.register-content .col-md-6.div-2 {
  padding-left: 0px !important;
  &:last-child {
    padding-right: 0px !important;
  }
}

.auth-form p.text-white {
  font-size: 16px;
}
.bank-info-form {
  margin-top: 60px !important;
}

.form-div {
  min-height: 160px;
}

// FORM IMAGES
.form-img-div {
  width: 330px;
  height: 325px;
  margin: auto;
}
.right-form-image {
  width: 100%;
  height: 300px;
}
.form-cover-img-div {
  width: 320px;
  height: 300px;
  margin: auto;
}
.vector-1-image {
  width: 100%;
  height: 300px;
}
.sm-form-cover-div {
  position: absolute;
  top: 52px;
  left: 28%;
  width: 200px;
  height: 200px;
}
.phone-success-image {
  width: 100%;
  height: 200px;
}

//INPUTS
.yellow-input {
  background-color: transparent !important;
  border-color: $primary !important;
  font-size: 15px;
  height: 40px;
  border-width: 2px;
  color: white !important;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  &::placeholder {
    font-size: 14.5px;
    color: white !important;
  }
}

.input-group-prepend .input-group-text {
  background-color: $lightSecondary;
  border-color: $primary;
  color: white !important;
  font-size: 15px;
  border-width: 2px;
  border-right: none !important;
}
.input-group-prepend.payment-info-grp .input-group-text {
  background-color: $dark;
  border: none !important;
  // border-width: 2px;
  // border-right: 2px solid white !important;
}
.auth-form-icon {
  font-size: 14px;
}

.styled-select {
  width: 100%;
  height: 40px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url(../images/shape.png) no-repeat;
  background-size: 15px 8px;
  background-position: 96% 14.5px;
  border: 2px solid $primary;
  display: block;
  position: relative;
  border-radius: 4px;
}

.styled-select select {
  -moz-appearance: none !important; /* Firefox */
  -webkit-appearance: none !important; /* Safari and Chrome */
  appearance: none !important;
  width: 100%;
  font-size: 15px;
  border: 0;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.styled-select select option {
  background-color: #000;
  font-size: 14px;
}

// CHECK BOX
.signup-checkBox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.signup-checkBox .custom-control-label {
  font-size: 13px;
  padding-top: 5px;
  padding-left: 3px;
  font-weight: bold;
}
.signup-checkBox .custom-control-label::before {
  width: 1rem;
  height: 1rem;
  background-color: transparent !important;
  border-width: 1.5px !important;
  border-color: $primary !important;
  color: $primary !important;
  margin-top: 5px;
  cursor: pointer !important;
}
.signup-checkBox .custom-control-label::after {
  width: 1rem;
  height: 1rem;
  background: no-repeat 50% / 50% 50%;
}

.signup-checkBox .custom-checkbox .custom-control-label::before {
  border-radius: 20%;
  cursor: pointer !important;
}

.signup-checkBox .custom-checkbox .custom-control-input {
  cursor: pointer !important;
}

.signup-checkBox
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: '';
  padding-left: 1px;
  padding-top: 25px;
  font-size: 12px !important;
  border-radius: 5px;
  cursor: pointer !important;
}

// Image Preview
.img-preview,
.art-info-pic-preview {
  border-radius: 10px;
}
.img-preview .preview-pic {
  width: 280px;
  height: 200px;
  object-fit: cover;
}
.img-preview .initial-pic {
  width: 180px;
  height: 180px;
  object-fit: cover;
}
.file-label {
  height: 38px;
  width: 140px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: auto;
}

// File input
.custom-file-label {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  border-width: 2px;
  border-color: $primary;
  height: 40px;
  cursor: pointer !important;
  padding: 0.428rem 0.75rem;
}

.custom-file-label::after {
  background-color: $lightSecondary;
  color: #fff;
  height: 40px;
  cursor: pointer !important;
  padding: 0.428rem 0.75rem;
}

.responsive-alert {
  display: none;
}

@media (max-width: 768px) {
  .auth-page {
    padding-bottom: 50px;
  }
  .responsive-alert {
    width: 80% !important;
    margin: auto;
    display: block;
  }
  .auth-page-content {
    width: 90%;
  }
  .register-content .col-md-6.div-2 {
    display: none;
  }
  .signup-form-nav span.disable-indicator,
  .signup-form-nav span.completed {
    display: none;
  }
  .signup-form-nav span.col-md-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .small-form-input-div {
    margin-right: 0px !important;
  }
  .success-content {
    margin-top: 0px !important;
    & .col-md-6.div-1 {
      text-align: center;
      padding-top: 0px !important;
      & h3 {
        margin-top: 10px !important;
      }
    }
  }

  .image-logo-round {
    left: 40%;
  }
  .auth-nav {
    text-align: center;
    a {
      color: #000;
      font-weight: bold;
    }
  }
}
