.modal-form-div {
  width: 80%;
  margin: auto;
}

.modal-content {
  margin-top: 25%;
}
.modal-content.footer-modal {
  margin-top: 0px !important;
}

.artist-modal {
  width: 80%;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.img-preview {
  width: 80%;
  height: 240px;
  margin: auto;
}
.art-info-pic-preview {
  width: 92%;
  height: 240px;
  margin: auto;
}
.id-form .img-preview {
  width: 80%;
  height: 270px !important;
  margin: auto;
}

.img-preview-set {
  width: 100%;
  height: 190px;
  word-break: break-all;
}

.img-preview img,
.img-preview-set img,
.art-info-pic-preview img {
  object-fit: cover;
}

.preview-img {
  margin: auto !important;
}

input[type='file'] {
  display: none;
}

.file-label,
.file-label-set {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: auto;
}

.file-label {
  height: 38px;
  width: 140px;
}

.file-label-set {
  height: 30px;
  width: 110px;
}

.img-preview-set .initial-set-pic {
  width: 120;
  height: 120;
  object-fit: fill;
}
.img-preview-set .initial-track-pic {
  width: 105;
  height: 125;
  object-fit: fill;
}

.song-indicator {
  height: 50%;
  padding-top: 25%;
}

.close-button {
  font-size: 26px !important;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 2% !important;
  top: 3.5% !important;
}

.modal-header {
  border-bottom: none !important;
}

.footer-modal .modal-header {
  border-bottom: 1px solid #fff !important;
}

.footer-modal h4 {
  font-size: 21px;
}
.footer-modal h5 {
  font-size: 19px;
}
.footer-modal h6 {
  font-size: 15px;
}

.payout-modal-content {
  margin-top: 10vh;
  height: 80vh;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .img-preview,
  .art-info-pic-preview,
  .img-preview-set {
    width: 100% !important;
    & img {
      width: 100% !important;
    }
  }
}

@media (max-width: 576px) {
  .img-preview,
  .art-info-pic-preview,
  .img-preview-set {
    width: 100% !important;
    & img {
      width: 100% !important;
    }
  }
}
