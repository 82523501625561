.tagClass {
  @extend .mr-1;
  @extend .my-1;
  @extend .px-2;
  @extend .small-text;
  background-color: #585858;
  border: 0.1px rgb(126, 126, 126) solid;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px;
}

.tagInputFieldClass {
  @extend .form-control;
  @extend .formInput;
}

.disableTagInput {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background-color: $dark !important;
  opacity: 0.25;
}

.selectedClass {
  @extend .text-left;
  @extend .mb-1;
}

.removeTagClass {
  @extend .ml-1;
  @extend .large-text;
  color: white;
  background-color: transparent;
  border: none;
  line-height: 3px;
  &:hover {
    @extend .cursor-pointer;
    @extend .py-2;
    color: $primary !important;
  }
}

.suggestionsClass {
  position: absolute;
  width: 89.8%;
  margin-top: 2px;
}

.suggestionsClass > ul {
  @extend .list-group;
  height: 200px !important;
  overflow: hidden;
}

.suggestionsClass > ul > li {
  @extend .list-group-item;
  @extend .cursor-pointer;
  @extend .medium-text;
  background-color: #585858;
  padding: 0.25rem 0.75rem;
  text-align: left;
  z-index: 1;
}

.suggestionsClass > ul > li:first-child {
  border-radius: 0;
}

.suggestionsClass > ul > li > span > mark {
  background-color: $primary;
  padding-right: 0;
  padding-left: 1px;
}
